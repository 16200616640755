// variable

// mixin
@mixin omit{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin clearfix{
    zoom: 1;
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden
    }
}

@mixin home_section_title{
    display: flex;
    color: #333333;
    ._section_title {
        font-size: 22px;
        font-weight: bold;
        line-height: 22px;
    }
    ._en {
        font-size: 13px;
        font-weight: bold;
        line-height: 13px;
        opacity: 0.1;
        margin-top: 9px;
        margin-right: 11px;
    }
    ._line {
        margin-top: 16px;
        width: 80px;
        height: 1px;
        background-color: #707070;
        opacity: 0.2;
    }
}

@mixin m_home_section_title{
    display: flex;
    color: #333333;
    ._section_title {
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
    }
    ._en {
        font-size: 10px;
        font-weight: bold;
        line-height: 11px;
        opacity: 0.1;
        margin-top: 7px;
        margin-right: 3px;
    }
    ._line {
        margin-top: 13px;
        width: 60px;
        height: 1px;
        background-color: #707070;
        opacity: 0.2;
    }
}

@mixin m_home_tech_content {
    text-align: center;
    margin-bottom: 76px;
    &:last-child {
        margin-bottom: 0;
    }
    ._content_title {
        font-size: 20px;
        font-weight: 550;
        line-height: 20px;
        color: #333333;
        margin-bottom: 12px;
    }
    ._content_text {
        font-size: 12px;
        margin-bottom: 14px;
        line-height: 18px;
        color: #696969;
    }
    ._content_button {
        @include view_detail_button;
    }
}

@mixin view_detail_button {
    cursor: pointer;
    font-size: 12px;
    width: 124px;
    height: 30px;
    line-height: 29px;
    text-align: center;
    border: 1px solid #333333;
    color: #333333;
    &:hover {
        border-color: #C43535;
        color: #C43535;
        a {
            color: #C43535!important;
        }
    }
}

@mixin tech_intro {
    text-align: center;
    background-size: cover;
    min-width: 1200px;
    ._title {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 23px;
        color: #333333;
        font-weight: 550;
    }
    ._text {
        margin: 0 auto;
        text-align: left;
        font-size: 16px;
        line-height: 28px;
        color: #696969;
    }
}

@mixin m_tech_intro {
    text-align: center;
    background-size: cover;
    ._title {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 11px;
        color: #333333;
        font-weight: 550;
    }
    ._text {
        margin: 0 auto;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        color: #696969;
    }
}

@mixin m_about_text_title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    color: #333333;
    margin-bottom: 12px;
    ._sub {
        font-size: 14px;
        line-height: 15px;
    }
}

@media screen and (min-width:769px) {
    ._aboutUs_container {
        background-color: #F5F5F5;
        padding:79px 0 60px;
        .m_text_content {
            display: none;
        }
        ._aboutUs_content {
            width: 1200px;
            margin: 0 auto;
        }
        ._title {
            @include home_section_title;
            margin-bottom: 96px;
        }
        ._item {
            display: flex;
            ._img {
                width: 595px;
                height: 401px;
            }
            ._text_title {
                font-size: 60px;
                font-weight: bold;
                line-height: 60px;
                color: #333333;
                margin-bottom: 27px;
                ._sub {
                    font-size: 22px;
                    line-height: 23px;
                }
            }
            ._text_content {
                font-size: 14px;
                line-height: 30px;
                color: #696969;
            }
        }
        ._introduction {
            margin-bottom: 40px;
            ._text {
                padding-top: 32px;
                margin-right: 171px;
                ._text_content {
                    max-width: 434px;
                }
            }
        }
        ._team {
            margin-bottom: 40px;
            ._img {
                margin-right: 60px;
            }
            ._text {
                padding-top: 83px;
                ._text_content {
                    max-width: 420px;
                }
            }
        }
        ._mission {
            margin-bottom: 80px;
            ._text {
                padding-top: 131px;
                margin-right: 463px;
            }
        }
        ._honor {
            ._text_title {
                text-align: center;
                font-size: 60px;
                font-weight: bold;
                line-height: 60px;
                color: #333333;
                margin-bottom: 43px;
                ._sub {
                    font-size: 22px;
                    line-height: 23px;
                }
            }
            ._honor_item {
                width: 372px;
                height: 376px;
                position: relative;
                ._honor_bg {
                    padding-top: 116px;
                }
                ._honor_certificate {
                    position: absolute;
                    left: 38px;
                    bottom: 13px;
                }
            }
            ._honor_line1 {
                margin-bottom: 29px;
                display: flex;
                justify-content: space-between;
            }
            ._honor_line2 {
                padding: 0 177px;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
