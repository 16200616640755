// variable

// mixin
@mixin omit{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin clearfix{
    zoom: 1;
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden
    }
}

@mixin home_section_title{
    display: flex;
    color: #333333;
    ._section_title {
        font-size: 22px;
        font-weight: bold;
        line-height: 22px;
    }
    ._en {
        font-size: 13px;
        font-weight: bold;
        line-height: 13px;
        opacity: 0.1;
        margin-top: 9px;
        margin-right: 11px;
    }
    ._line {
        margin-top: 16px;
        width: 80px;
        height: 1px;
        background-color: #707070;
        opacity: 0.2;
    }
}

@mixin m_home_section_title{
    display: flex;
    color: #333333;
    ._section_title {
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
    }
    ._en {
        font-size: 10px;
        font-weight: bold;
        line-height: 11px;
        opacity: 0.1;
        margin-top: 7px;
        margin-right: 3px;
    }
    ._line {
        margin-top: 13px;
        width: 60px;
        height: 1px;
        background-color: #707070;
        opacity: 0.2;
    }
}

@mixin m_home_tech_content {
    text-align: center;
    margin-bottom: 76px;
    &:last-child {
        margin-bottom: 0;
    }
    ._content_title {
        font-size: 20px;
        font-weight: 550;
        line-height: 20px;
        color: #333333;
        margin-bottom: 12px;
    }
    ._content_text {
        font-size: 12px;
        margin-bottom: 14px;
        line-height: 18px;
        color: #696969;
    }
    ._content_button {
        @include view_detail_button;
    }
}

@mixin view_detail_button {
    cursor: pointer;
    font-size: 12px;
    width: 124px;
    height: 30px;
    line-height: 29px;
    text-align: center;
    border: 1px solid #333333;
    color: #333333;
    &:hover {
        border-color: #C43535;
        color: #C43535;
        a {
            color: #C43535!important;
        }
    }
}

@mixin tech_intro {
    text-align: center;
    background-size: cover;
    min-width: 1200px;
    ._title {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 23px;
        color: #333333;
        font-weight: 550;
    }
    ._text {
        margin: 0 auto;
        text-align: left;
        font-size: 16px;
        line-height: 28px;
        color: #696969;
    }
}

@mixin m_tech_intro {
    text-align: center;
    background-size: cover;
    ._title {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 11px;
        color: #333333;
        font-weight: 550;
    }
    ._text {
        margin: 0 auto;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        color: #696969;
    }
}

@mixin m_about_text_title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    color: #333333;
    margin-bottom: 12px;
    ._sub {
        font-size: 14px;
        line-height: 15px;
    }
}

@media screen and (min-width:769px) {
    ._home {
        height: 100%;
        position: relative;
        .m_title_bg,.slick-slider {
            display: none;
        }
        ._home_content {
            min-height: calc(100% - 60px);
            margin: 0 auto;
            ._title {
                width: 100%;
                min-width: 1200px;
                position: relative;
                ._title_bg {
                    width: 100%;
                    height: auto;
                }
                ._title_content {
                    position: absolute;
                    top: 27.3%;
                    width: 100%;
                    text-align: center;
                    color: #333333;
                    ._line1 {
                        font-size: 50px;
                        font-weight: bold;
                        line-height: 50px;
                        margin-bottom: 24px;
                    }
                    ._line2 {
                        font-size: 32px;
                        line-height: 32px;
                    }
                }
            }
            ._coreTech {
                margin: 0 auto;
                padding: 68px 0 57px;
                width: 1200px;
                ._coreTech_text {
                    @include home_section_title;
                    margin-bottom: 96px;
                }
                ._coreTech_content {
                    display: flex;
                    ._content_title {
                        font-size: 26px;
                        font-weight: 550;
                        line-height: 26px;
                        color: #333333;
                    }
                    ._content_text {
                        font-size: 12px;
                        color: #696969;
                    }
                    ._content_button {
                        @include view_detail_button;
                    }
                    ._LeagugChain {
                        width: 192px;
                        margin-right: 104px;
                        ._icon {
                            width: 83px;
                            height: 83px;
                            margin-bottom: 20px;
                        }
                        ._content_title {
                            margin-bottom: 16px;
                        }
                        ._content_text {
                            margin-bottom: 46px;
                            line-height: 20px;
                        }
                    }
                    ._gateway {
                        width: 243px;
                        margin-right: 105px;
                        ._icon {
                            width: 54px;
                            height: 82px;
                            margin-bottom: 21px;
                        }
                        ._content_title {
                            margin-bottom: 16px;
                        }
                        ._content_text {
                            margin-bottom: 22px;
                            line-height: 21px;
                        }
                    }
                    ._storage {
                        width: 218px;
                        margin-right: 104px;
                        padding-top: 4px;
                        ._icon {
                            width: 94px;
                            height: 91px;
                            margin-bottom: 8px;
                        }
                        ._content_title {
                            margin-bottom: 16px;
                        }
                        ._content_text {
                            margin-bottom: 22px;
                            line-height: 21px;
                        }
                    }
                    ._Iot {
                        width: 233px;
                        margin-right: 1px;
                        padding-top: 8px;
                        ._icon {
                            width: 67px;
                            height: 67px;
                            margin-bottom: 28px;
                        }
                        ._content_title {
                            margin-bottom: 16px;
                        }
                        ._content_text {
                            margin-bottom: 46px;
                            line-height: 20px;
                        }
                    }
                }
            }
            ._news_cooperation {
                margin: 0 auto;
                padding: 93px 0 88px;
                width: 1200px;
                ._news {
                    ._title_line {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 60px;
                        ._news_title {
                            @include home_section_title;
                        }
                        ._more {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            ._more_text {
                                color: #696969;
                                font-size: 14px;
                                margin-right: 5px;
                            }
                            ._more_icon {
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }
                    ._news_content {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 133px;
                        ._news_item {
                            width: 280px;
                            position: relative;
                            ._icon_border{
                                width: 280px;
                                height: 200px;
                                margin-bottom: 8px;
                                overflow: hidden;

                                ._icon {
                                    width: 280px;
                                    height: 200px;
                                    transition: all 0.5s;
                                }
                                ._icon:hover {
                                    transform:scale(1.2);
                                    width: 280px;
                                    height: 200px;
                                    transition: all 0.5s;
                                }
                            }

                            ._content_title {
                                font-size:13px;
                                font-weight:550;
                                line-height:16px;
                                color:#333333;
                                margin-bottom:13px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            ._content_text {
                                font-size: 11px;
                                line-height: 18px;
                                margin-bottom: 47px;
                                color: #696969;
                            }
                            ._content_button {
                                @include view_detail_button;
                                position: absolute;
                                bottom: 0;
                            }
                        }
                    }
                }
                ._cooperation {
                    ._cooperation_title {
                        @include home_section_title;
                        margin-bottom: 53px;
                    }
                    ._cooperation_list {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        ._partner {
                            // width: 160px;
                            // height: 80px;
                            ._partner_icon {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
