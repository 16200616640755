// variable

// mixin
@mixin omit{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin clearfix{
    zoom: 1;
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden
    }
}

@mixin home_section_title{
    display: flex;
    color: #333333;
    ._section_title {
        font-size: 22px;
        font-weight: bold;
        line-height: 22px;
    }
    ._en {
        font-size: 13px;
        font-weight: bold;
        line-height: 13px;
        opacity: 0.1;
        margin-top: 9px;
        margin-right: 11px;
    }
    ._line {
        margin-top: 16px;
        width: 80px;
        height: 1px;
        background-color: #707070;
        opacity: 0.2;
    }
}

@mixin m_home_section_title{
    display: flex;
    color: #333333;
    ._section_title {
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
    }
    ._en {
        font-size: 10px;
        font-weight: bold;
        line-height: 11px;
        opacity: 0.1;
        margin-top: 7px;
        margin-right: 3px;
    }
    ._line {
        margin-top: 13px;
        width: 60px;
        height: 1px;
        background-color: #707070;
        opacity: 0.2;
    }
}

@mixin m_home_tech_content {
    text-align: center;
    margin-bottom: 76px;
    &:last-child {
        margin-bottom: 0;
    }
    ._content_title {
        font-size: 20px;
        font-weight: 550;
        line-height: 20px;
        color: #333333;
        margin-bottom: 12px;
    }
    ._content_text {
        font-size: 12px;
        margin-bottom: 14px;
        line-height: 18px;
        color: #696969;
    }
    ._content_button {
        @include view_detail_button;
    }
}

@mixin view_detail_button {
    cursor: pointer;
    font-size: 12px;
    width: 124px;
    height: 30px;
    line-height: 29px;
    text-align: center;
    border: 1px solid #333333;
    color: #333333;
    &:hover {
        border-color: #C43535;
        color: #C43535;
        a {
            color: #C43535!important;
        }
    }
}

@mixin tech_intro {
    text-align: center;
    background-size: cover;
    min-width: 1200px;
    ._title {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 23px;
        color: #333333;
        font-weight: 550;
    }
    ._text {
        margin: 0 auto;
        text-align: left;
        font-size: 16px;
        line-height: 28px;
        color: #696969;
    }
}

@mixin m_tech_intro {
    text-align: center;
    background-size: cover;
    ._title {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 11px;
        color: #333333;
        font-weight: 550;
    }
    ._text {
        margin: 0 auto;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        color: #696969;
    }
}

@mixin m_about_text_title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    color: #333333;
    margin-bottom: 12px;
    ._sub {
        font-size: 14px;
        line-height: 15px;
    }
}


._main {
    height: 100%;
    @media screen and (min-width:0) and (max-width:768px) {
        ._main_route {
            height: calc(100% - 43px);
            padding-top: 43px;
            overflow-y: auto;
        }
        .file_logo{
            position: fixed;
            top: 85vh;
            right: 20px;
            width: 40px;
            cursor: pointer;
            height: 40px;
            border-radius: 29px;
            transition: 1s all;
            background: #49F3D0;
            background: -webkit-linear-gradient(to right, #7A30FD, #49F3D0);
            background: linear-gradient(to right, #7A30FD, #49F3D0);
            display: flex;
            z-index: 1000;
            flex-direction: row;
            .file_icon{
                width: 40px;
                height: 40px;
            }
            .filup{
                opacity: 0;
                color:#fff;
                z-index:-100;
                font-size: 0;

            }
            .close{
                display: none;
            }
        }
        .file_logo:hover{
            display: flex;
            flex-direction: row;
            transition: 0.5s all;
            width: 120px;
            height: 40px;
            background: #49F3D0;
            background: -webkit-linear-gradient(to right, #7A30FD, #49F3D0);
            background: linear-gradient(to right, #7A30FD, #49F3D0);
            border-radius: 39px;
            .filup{
                overflow: hidden;
                transition: 2s all;
                position: relative;
                left: 40px;
                top: -42px;
                display: flex;
                width: 80px;
                z-index:100;
                height: 40px;
                opacity: 1;
                color:#fff;
                font-size: 12px;
                justify-content: center;
                align-items: center;
            }
            .close{
                display: flex;
                width: 20px;
                height: 20px;
                position: absolute;
                top:-10px;
                right:-10px;
                cursor: pointer;
            }
        }
    }
    @media screen and (min-width:769px) {
        ._main_route {
            height: calc(100% - 60px);
        }
        .file_logo{
            position: fixed;
            top: 50vh;
            left: 20px;
            width: 50px;
            cursor: pointer;
            height: 50px;
            border-radius: 39px;
            transition: 1s all;
            background: #49F3D0;
            background: -webkit-linear-gradient(to right, #7A30FD, #49F3D0);
            background: linear-gradient(to right, #7A30FD, #49F3D0);
            display: flex;
            z-index: 1000;
            flex-direction: row;
            .file_icon{
                width: 50px;
                height: 50px;
            }
            .filup{
                opacity: 0;
                color:#fff;
                z-index:-100;
                font-size: 18px;

            }
            .close{
                display: none;
            }
        }
        .file_logo:hover{
            display: flex;
            flex-direction: row;
            transition: 0.5s all;
            width: 189px;
            height: 50px;
            background: #49F3D0;
            background: -webkit-linear-gradient(to right, #7A30FD, #49F3D0);
            background: linear-gradient(to right, #7A30FD, #49F3D0);
            border-radius: 39px;
            .filup{
                overflow: hidden;
                transition: 2s all;
                position: relative;
                left: 50px;
                top: -52px;
                display: flex;
                width: 130px;
                z-index:100;
                height: 50px;
                opacity: 1;
                color:#fff;
                font-size: 18px;
                justify-content: center;
                align-items: center;
            }
            .close{
                display: flex;
                width: 20px;
                height: 20px;
                position: absolute;
                top:-10px;
                right:-10px;
                cursor: pointer;
            }
        }
    }
}
