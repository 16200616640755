// variable

// mixin
@mixin omit{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin clearfix{
    zoom: 1;
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden
    }
}

@mixin home_section_title{
    display: flex;
    color: #333333;
    ._section_title {
        font-size: 22px;
        font-weight: bold;
        line-height: 22px;
    }
    ._en {
        font-size: 13px;
        font-weight: bold;
        line-height: 13px;
        opacity: 0.1;
        margin-top: 9px;
        margin-right: 11px;
    }
    ._line {
        margin-top: 16px;
        width: 80px;
        height: 1px;
        background-color: #707070;
        opacity: 0.2;
    }
}

@mixin m_home_section_title{
    display: flex;
    color: #333333;
    ._section_title {
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
    }
    ._en {
        font-size: 10px;
        font-weight: bold;
        line-height: 11px;
        opacity: 0.1;
        margin-top: 7px;
        margin-right: 3px;
    }
    ._line {
        margin-top: 13px;
        width: 60px;
        height: 1px;
        background-color: #707070;
        opacity: 0.2;
    }
}

@mixin m_home_tech_content {
    text-align: center;
    margin-bottom: 76px;
    &:last-child {
        margin-bottom: 0;
    }
    ._content_title {
        font-size: 20px;
        font-weight: 550;
        line-height: 20px;
        color: #333333;
        margin-bottom: 12px;
    }
    ._content_text {
        font-size: 12px;
        margin-bottom: 14px;
        line-height: 18px;
        color: #696969;
    }
    ._content_button {
        @include view_detail_button;
    }
}

@mixin view_detail_button {
    cursor: pointer;
    font-size: 12px;
    width: 124px;
    height: 30px;
    line-height: 29px;
    text-align: center;
    border: 1px solid #333333;
    color: #333333;
    &:hover {
        border-color: #C43535;
        color: #C43535;
        a {
            color: #C43535!important;
        }
    }
}

@mixin tech_intro {
    text-align: center;
    background-size: cover;
    min-width: 1200px;
    ._title {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 23px;
        color: #333333;
        font-weight: 550;
    }
    ._text {
        margin: 0 auto;
        text-align: left;
        font-size: 16px;
        line-height: 28px;
        color: #696969;
    }
}

@mixin m_tech_intro {
    text-align: center;
    background-size: cover;
    ._title {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 11px;
        color: #333333;
        font-weight: 550;
    }
    ._text {
        margin: 0 auto;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        color: #696969;
    }
}

@mixin m_about_text_title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    color: #333333;
    margin-bottom: 12px;
    ._sub {
        font-size: 14px;
        line-height: 15px;
    }
}

@media screen and (min-width:0) and (max-width:768px) {
    ._aboutUs_container {
        background-color: #F5F5F5;
        padding: 60px 20px 72px;
        ._aboutUs_content {
            margin: 0 auto;
        }
        ._title {
            @include m_home_section_title;
            margin-bottom: 26px;
        }
        ._item {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-bottom: 76px;
            ._img {
                width: 311px;
                height: 210px;
                margin: 0 auto;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            ._text_title {
                @include m_about_text_title;
            }
            ._text_content,.m_text_content {
                font-size: 12px;
                line-height: 22px;
                color: #696969;
                margin-bottom: 22px;
            }
        }
        ._introduction {
            ._text_content {
                display: none;
            }
        }
        ._team {
            flex-direction: column-reverse;
        }
        ._honor {
            ._text_title {
                @include m_about_text_title;
                margin-bottom: 22px!important;
            }
            ._honor_item {
                width: 100%;
                height: 340px;
                margin-bottom: 45px;
                position: relative;
                padding-top: 106px;
                text-align: center;
                &:last-child {
                    margin-bottom: 0;
                }
                ._honor_bg {
                    width: 100%;
                    height: 100%;
                }
                ._honor_certificate {
                    width: 269px;
                    height: 328px;
                    position: absolute;
                    bottom: 12px;
                    // 绝对定位居中
                    left: 50%;
                    transform:translateX(-50%);
                }
            }
            ._honor_line1 {
                margin-bottom: 45px;
            }
            ._honor_line1, ._honor_line2 {
                display: flex;
                flex-direction: column;
            }
        }
    }

}
