html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-weight: normal;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section{
    display: block;
}
ol, ul, li{
    list-style: none;
}
blockquote, q{
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after{
    content: '';
    content: none;
}
table{
    border-collapse: collapse;
    border-spacing: 0;
}

/* custom */
a{
    color: #6753FF;
    text-decoration: none;
}

/* ::-webkit-scrollbar{
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-track-piece{
    background-color: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:vertical{
    height: 5px;
    background-color: rgba(125, 125, 125, 0.7);
}
::-webkit-scrollbar-thumb:horizontal{
    width: 5px;
    background-color: rgba(125, 125, 125, 0.7);
} */
html, body{
    scroll-behavior: smooth;
    width: 100%;
    font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", "微软雅黑", sans-serif;
}
body{
    line-height: 1;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: Source Han Sans CN;
}
/* html{
    overflow-y: scroll;
} */

/*清除浮动*/
.clearfix:before,
.clearfix:after{
    content: " ";
    display: inline-block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.clearfix{
    *zoom: 1;
}

/*隐藏*/
.dn{
    display: none;
}

.ant-popover-title span{
    font-weight: bold;
    font-size: 18px;
}

.download-link {
    display: flex;
}
.service {
    text-align: center;
    height: 100%;
}
.sub {
    text-align: center;
}
@media screen and (min-width:0) and (max-width:768px) {
    html {
        height: auto;
    }
    .download-link {
        flex-direction: column;
    }
    ._overlay_wechat {
        width: 230px!important;
        height: 445px!important;
    }
    .download-icon {
        width: 85%;
    }
    .service {
        margin-bottom: 20px;
    }
}
@media screen and (min-width:769px) {
    /* 定义宽度防止Popover组件加载位置偏移 */
    ._overlay_wechat {
        width: 414px!important;
    }
    .ant-popover-inner-content {
        padding: 10px 10px 16px;
    }
    .download-icon {
        width: 172px;
        height: 172px;
    }
    .link-text {
        font-size:12px;
        line-height:17px;
        color:rgba(22,28,40,1);
    }
    .service {
        margin-right: 50px;
    }
    .service, .sub {
        height: 189px;
    }
}

@media screen and (min-width:0) and (max-width:1200px) {
    @media screen and (min-device-width:1224px) {
        html,body {
            /* width:1200px!important;
            overflow-x: scroll; */
        }
        input::-webkit-input-placeholder {
                    /* placeholder颜色  */
            color: #fff;
            /* placeholder字体大小  */
            font-size: 12px!important;
        }
    }

    @media screen and (max-device-width:1224px) {
        html,body {
            width:100%!important;
            overflow-x: hidden!important;
        }
        input::-webkit-input-placeholder {
                /* placeholder颜色  */
            color: #666;
            /* placeholder字体大小  */
            font-size: 12px!important;
        }
    }
}
.ant-modal-body {
    padding: 43px 32px!important;
}
input::-webkit-input-placeholder {
    color: #8F9EBB!important;
}
input:-moz-placeholder {
    color: #8F9EBB!important;
}
input::-moz-placeholder {
    color: #8F9EBB!important;
}
input:-ms-input-placeholder {
    color: #8F9EBB!important;
}
.ant-input {
    border: 1px solid #d9d9d9!important;
    height: 42px!important;
    padding: 13px 4px!important;
    background-color: #BEC9E1!important;
}
.ant-input-search-button {
    height: 42px!important;
    color: #fff!important;
    background-color: #456CBC!important;
}
.ant-modal-close-x {
    width: 30px!important;
    line-height: 30px!important;
    height: 30px!important;
}
.ant-dropdown-menu-item {
    padding: 8px 15px!important;
    font-size: 15px!important;
}


